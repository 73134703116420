import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class ProductList extends ServiceBase {
  async getPricingModal(idCompany, idPriceGroup, curve, status) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/PriceGroups/SceneryInfo?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}&curve=${curve}&status=${status}`
    );
  }

  async extractReport(request) {
    return await axiosInstance.post(
      API_URL +
        `api/V2/Enterprise/PriceProjection/ListExport`,
        request,
      {
        responseType: "blob",
      }
    );
  }

  async getMarketAnalysis(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetMarketResult?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getElasticity(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetElasticity?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getProductInfo(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetPriceProjectionSideInfo?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getPricingInfo(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetPriceProjectionSuggestedPriceInfo?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getOptimizedPrices(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetOptimizedPrice?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getRulePath(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetPriceProjectionRulePath?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getMarketResultItem(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetMarketResultItem?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getWorkFlowState(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetWorkflowState?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getBoldClusters(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetPriceProjectionDiscountPrice?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async getDataById(idEnterprisePriceProjection) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/Pricing/GetById?idEnterprisePriceProjection=${idEnterprisePriceProjection}`
    );
  }

  async setOptimizedPrice(request) {
    return await axiosInstance.post(
      API_URL + `api/v2/Enterprise/Pricing/AddValueManual`,
      request
    );
  }

  async setActionWorkFlow(request) {
    return await axiosInstance.post(
      API_URL + `api/v2/Enterprise/Pricing/SetActionWorkflow`,
      request
    );
  }
}
