<template>
  <v-row no-gutters align="end" justify="space-between">
    <v-col cols="6">
      <Prd-tabs
        :configs="tabs[0].config"
        @changedTab="tabs[0].action($event)"
      />
    </v-col>

    <v-col cols="6">
      <v-row no-gutters justify="end" align="end" class="gap-4">
        <v-col>
          <Prd-autocomplete-chip
            v-if="hasMachineryResource"
            :originalValue="selectedYears"
            :title="$t('TXT_YEARS')"
            :label="$t('TXT_SELECT_ONE_OR_MORE_YEARS')"
            :items="vehicleYearModelList"
            @click.clear="selectedYears = []"
            @updates="selectedYears = $event"
          />
        </v-col>
        <v-col cols="auto">
          <Prd-tabs
            :configs="tabs[1].config"
            @changedTab="tabs[1].action($event)"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PrdAutocompleteChip from "@/components/common/prd-autocomplete-chip.vue";

import PrdTabs from "@/components/common/prd-tabs.vue";
export default {
  components: { PrdTabs, PrdAutocompleteChip },
  data() {
    return {
      tabs: [
        {
          config: {
            title: this.$i18n.t("TXT_GROUP_BY"),
            tabs: [
              {
                name: this.$i18n.t("TXT_PRODUCT"),
                tooltip: this.$i18n.t("TXT_COMPARISON_TOOLTIP_PRODUCT"),
              },
              {
                name: this.$i18n.t("TXT_RETAILER"),
                tooltip: this.$i18n.t("TXT_TEMPORAL_TOOLTIP_ORIGIN"),
              },
              {
                name: this.$i18n.t("TXT_MANUFACTURER"),
                tooltip: this.$i18n.t("TXT_TEMPORAL_TOOLTIP_RETAILER_2"),
              },
            ],
          },
          action: (index) => {
            this.$store.dispatch("setGroupBy", index);
          },
        },

        {
          config: {
            title: this.$i18n.t("TXT_VISUALIZATION"),
            tabs: [
              {
                name: this.$i18n.t("TXT_LIST"),
              },
              {
                name: this.$i18n.t("TXT_CHART"),
              },
            ],
          },
          action: (index) => {
            this.$store.dispatch("setVisualization", index);
          },
        },
      ],
      selectedYears: [],
    };
  },
  computed: {
    vehicleYearModelList() {
      return this.$store.getters.vehicleYearModelList ?? [];
    },
    hasMachineryResource() {
      return this.$store.getters.hasResource("marketplace.machinery") ?? false;
    },
  },
  watch: {
    selectedYears: {
      handler(value) {
        this.$store.dispatch("setVehicleYearModels", value);
      },
    },
  },
};
</script>
