<template>
  <v-dialog v-model="modalOpen" persistent width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="$prdStyles('color-primary')" dark v-bind="attrs" v-t="'TXT_SELECT_SKU_BUTTON'" v-on="on" class="mb-4">
      </v-btn>
    </template>

    <v-card>
      <v-row no-gutters align="center">
        <v-card-title
          class="text-h6 font-weight-bold ml-7 mb-n10"
          v-t="'TXT_SELECT_SKU'"
        >
        </v-card-title>
        <v-icon
          :color="$prdStyles('color-primary')"
          :disabled="loadingReport"
          @click="modalOpen = false"
          :class="VEM ? 'ml-auto mt-10 mr-4' : 'ml-auto mt-10 mr-4'"
          >mdi-close</v-icon
        >
      </v-row>

      <template>
        <v-container fluid>
          <v-checkbox
            class="ml-12 mb-n7"
            v-model="semaphoreNameFilter"
            :label="$t('TXT_SELECT_APPROVED_SKU')"
            :value="1"
          ></v-checkbox>
          <v-checkbox
            class="ml-12 mb-n7"
            v-model="semaphoreNameFilter"
            :label="$t('TXT_SELECT_MANUAL_SKU')"
            :value="2"
          ></v-checkbox>
          <v-checkbox
            class="ml-12 mb-n7"
            v-model="semaphoreNameFilter"
            :label="$t('TXT_SELECT_REPROVED_SKU')"
            :value="3"
          ></v-checkbox>
          <v-checkbox
            class="ml-12 mb-n7"
            v-model="semaphoreNameFilter"
            :label="$t('TXT_SELECT_WITHOUT_RULES_APPLIED_SKU')"
            :value="4"
          ></v-checkbox>
        </v-container>
      </template>

      <v-card-actions>
        <v-spacer></v-spacer>
        <Prd-btn
          :title="$t('TXT_EXTRACT_REPORT')"
          :color="$prdStyles('color-primary')"
          :icon="'mdi-list-box-outline'"
          :leftIcon="true"
          :disabled="isLoading || loadingReport"
          :loading="loadingReport"
          @click="extractReport"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/pricer/product-list.js";
import { downloadXlsx } from "@/utils/downloadData.js";
const Service = new service();
export default {
  components: { PrdBtn },
  props: ["isLoading"],
  data() {
    return {
      modalOpen: false,
      loadingReport: false,
      semaphoreNameFilter: [1, 2],
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    VEM() {
      return this.idCompany == 3026;
    },
    idPriceGroup() {
      return this.$store?.getters?.idPriceGroup ?? null;
    },
  },
  methods: {
    downloadXlsx,
    buildRequest() {
      return {
        idCompany: this.idCompany,
        IdEnterprisePriceGroups: this.idPriceGroup,
        semaphoreNameFilter: this.semaphoreNameFilter,
      };
    },
    async extractReport() {
      this.loadingReport = true;
      try {
        let request = this.buildRequest()
        let response = await Service.extractReport(request);
        downloadXlsx(response.data, "precificacao_cenario.xlsx");
      } catch (error) {
        console.log(error);
        this.$handleError(error, this.$i18n.t("TXT_ERROR"));
      }

      this.loadingReport = false;
    },
  },
};
</script>

<style>
</style>