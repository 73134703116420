import { render, staticRenderFns } from "./user-permission.vue?vue&type=template&id=2a1900ac&scoped=true"
import script from "./user-permission.vue?vue&type=script&lang=js"
export * from "./user-permission.vue?vue&type=script&lang=js"
import style0 from "./user-permission.vue?vue&type=style&index=0&id=2a1900ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a1900ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCheckbox,VCol,VRow})
