var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.smAndUp)?_c('v-navigation-drawer',{staticStyle:{"min-width":"88px"},attrs:{"mini-variant":_vm.mini,"expand-on-hover":"","permanent":"","app":"","id":"sidebar","color":"var(--dark-moderate-blue)"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"pa-0 mt-0",staticStyle:{"height":"88px"}},[_c('v-list-item',{staticClass:"pa-1",staticStyle:{"max-width":"88px"}},[_c('v-img',{staticClass:"my-0 mx-auto",staticStyle:{"max-width":"46px"},attrs:{"src":require("../../assets/seta-logo.png")}})],1),_c('v-list-item',{staticClass:"pa-0"},[_c('v-img',{staticStyle:{"max-width":"126px"},attrs:{"src":require("../../assets/name-logo.png")}})],1)],1),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(
          _vm.companyRequired(item.companyRequired) &&
          _vm.adminRequired(item.adminOnly) &&
          _vm.adminPredifyOnly(item.predifyAdminOnly) &&
          _vm.showToCompany(item.showToCompany) &&
          _vm.hideToCompany(item.hideToCompany) &&
          _vm.showToResource(item.showToResource) &&
          _vm.hideToResource(item.hideToResource) &&
          _vm.hideToRoles(item.hideToRoles)
        )?_c('v-list-item',{staticClass:"ma-1",style:({ color: 'var(--white)' }),attrs:{"link":""},on:{"click":function($event){return _vm.redirect(item)}}},[_c('v-list-item-icon',{staticClass:"my-0 mx-auto pl-2 pr-3 py-3",attrs:{"center":""}},[_c('font-awesome-icon',{class:{ isAdmin: item.predifyAdminOnly },attrs:{"icon":item.icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{ isAdmin: item.predifyAdminOnly }},[_vm._v(_vm._s(item.title))])],1)],1):_vm._e()],1)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }