<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="4" class="pa-0 ma-0">
        <Prd-combo-box
          v-model="selectedRule"
          :items="rules"
          :title="$t('TXT_RULES')"
        />
      </v-col>
      <v-icon
        @click="addRule"
        :color="$prdStyles('color-success')"
        :disabled="!selectedRule"
        size="30px"
        class="mt-6 ml-3"
      >
        mdi-plus-box
      </v-icon>
      
      <v-expansion-panels accordion class="drag-panel mt-6">
        <draggable
          v-model="selectedRules"
          @start="drag = true"
          @end="drag = false"
          handle=".handle"
        >
          <Main-rule-item
            v-for="(rule, index) in selectedRules"
            :key="index"
            :item="rule"
            :index="index"
            :idRule="idRule"
            @deleteitem="removeRule"
            class="mb-4"
          />
        </draggable>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import draggable from "vuedraggable";
import MainRuleItem from "../../handler-rule-datas/components/main-rule-item.vue";

export default {
  props: {
    idRule: {
      type: Number,
      default: 0,
    },
  },
  components: { PrdComboBox, draggable, MainRuleItem },
  data() {
    return {
      selectedRule: null,
    };
  },
  computed: {
    selectedRules: {
      get() {
        return this.$store.getters.getConditionsByIdRule(this.idRule);
      },
      set(value) {
        this.$store.dispatch("updateSequenceRules", {
          idRule: this.idRule,
          updatedList: value,
        });
      },
    },
    rules() {
      const allList = this.$store.getters.getRules;
      const allResources = this.$store.getters.resources;
      const filteredList = allList.filter((item) => {
        if (item.showWithResource == "all" || !item.showWithResource)
          return item;
        else {
          const requiredResource = item.showWithResource;
          const isExistsResources = allResources.some((res) =>
            res.includes(requiredResource)
          );
          if (isExistsResources) return item;
        }
      });
      return filteredList;
    },
  },

  methods: {
    addRule() {
      this.$store.dispatch("addRule", {
        conditionType: this.selectedRule.conditionType,
        idRule: this.idRule,
      });

      this.selectedRule = null;
    },

    removeRule(index) {
      this.$store.dispatch("removeRule", {
        index,
        idRule: this.idRule,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-panel {
  display: block !important;
}
</style>