import FiltersPredimonitorService from "@/service/predimonitor/filters-predimonitor-service-v2";
import PredimonitorService from "@/service/predimonitor/predimonitor-service";
import { handlerResponseCode } from "./utils/handler-response-code";

const PredimonitorStore = {
  state: {
    isLoading: false,
    results: [],
    lastRequest: null,
    dateForRentalReport: null,
  },
  getters: {
    getIsDisabled: (state) => state.results.length == 0,
  },
  mutations: {
    toggleLoading(state) {
      state.isLoading = !state.isLoading;
    },
    setResults(state, newResults) {
      state.results = newResults && Array.isArray(newResults) ? newResults : [];
    },
    setLastRequest(state, lastRequest) {
      state.lastRequest = lastRequest;
    },
    setDateForRentalReport(state, date) {
      state.dateForRentalReport = date;
    },
  },
  actions: {
    async getResults({ commit, state, dispatch }, request) {
      commit("toggleLoading");
      commit("setResults", null);
      await dispatch("updateLastRequest", request);
      const currentRequest = request ?? state.lastRequest;
      const enableV2 = () => {
        return process.env.VUE_APP_ENABLE_SEARCH_V2_PREDIMONITOR;
      };
      const useV2 = enableV2() == "true";
      try {
        if (useV2) {
          const service = new FiltersPredimonitorService();
          const response = await service.getResults(currentRequest);
          handlerResponseCode(commit, response.status);

          if (response.status != 204) {
            commit("setResults", response?.data ?? []);
            await dispatch(
              "setResultsForOtherComponents",
              response?.data ?? []
            );
          }
        } else {
          const service = new PredimonitorService();
          const response = await service.AvgInfoByProduct(currentRequest);

          if (response?.data?.answer?.registers?.length > 0) {
            commit("setResults", response?.data?.answer?.registers ?? []);
            await dispatch(
              "setResultsForOtherComponents",
              response?.data?.answer?.registers ?? []
            );
          } else handlerResponseCode(commit, 204);
        }
      } catch (error) {
        handlerResponseCode(commit, error.response.status);
      } finally {
        commit("toggleLoading");
      }
    },
    async updateLastRequest({ commit }, lastRequest) {
      if (!lastRequest) return;
      commit("setLastRequest", null);
      commit("setLastRequest", lastRequest);
    },
    async setResultsForOtherComponents({ dispatch }, data) {
      await dispatch("setResultsForProductComparision", data);
      await dispatch("setResultsForAnnouncementMapV2", data);
    },
  },
};

export default PredimonitorStore;
