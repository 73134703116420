<template>
  <Prd-btn
    :title="$t('TXT_RENTAL_REPORT')"
    @click.stop="getRentalReport"
    :loading="isLoadingRentalReport"
  />
</template>

<script>
import { downloadXlsx } from "@/utils/downloadData";
import PredimonitorService from "@/service/predimonitor/predimonitor-service";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  components: {
    PrdBtn,
  },
  data() {
    return {
      isLoadingRentalReport: false,
      service: new PredimonitorService(),
    };
  },
  methods: {
    async getRentalReport() {
      this.isLoadingRentalReport = true;
      try {
        const date = this.$store.state.PredimonitorStore.dateForRentalReport;
        const response = await this.service.getRentalReport(date);
        downloadXlsx(response.data, this.$i18n.t("TXT_RENTAL_REPORT"));
      } catch (error) {
        this.$handleError(error, "Erro ao tentar baixar relatório costumizado");
      } finally {
        this.isLoadingRentalReport = false;
      }
    },
  },
};
</script>
